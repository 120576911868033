






















/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import { mixins } from 'vue-class-component';
import ObjectSize from '../mixins/objectSize.mixin';
import Toaster from '../mixins/toaster.mixin';

@Component({
  components: {
    editor: Editor,
  },
})
export default class RichTextComponent extends mixins(ObjectSize, Toaster) {
  @Prop({ default: '' }) readonly initContent!: string;

  @Prop({ default: true }) readonly required!: boolean;

  @Prop({ default: false }) readonly validateOnInit!: boolean;

  @Prop(Number) readonly maxLength!: number;

  @Prop(Number) readonly richTextIndex!: number;

  config = {
    menubar: false,
    branding: false,
    toolbar:
       'undo redo | '
      + 'removeformat | '
      + 'formatselect fontsizeselect | '
      + 'bold italic underline subscript superscript strikethrough blockquote| '
      + 'forecolor backcolor | '
      + 'outdent indent | '
      + 'alignleft aligncenter alignright alignjustify | '
      + 'ltr rtl | '
      + 'numlist bullist checklist | '
      + 'quickimage mathjax',
    plugins: [
      'advlist autolink lists image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen directionality',
      'insertdatetime media table paste code help wordcount quickbars mathjax wordcount paste',
    ],
    min_height: 100,
    paste_data_images: true,
    quickbars_insert_toolbar: false,
    quickbars_selection_toolbar:
      'bold italic underline strikethrough blockquote | formatselect',
    language_url: '/tinymce/langs/ar.js',
    mathjax: {
      lib: '/mathJax/es5/tex-mml-chtml.js', // required path to mathjax
    },
  };

  content = this.initContent;

  editorRef: any = null;

  valid = true;

  error = '';

  get init() {
    return {
      ...this.config,
      language: this.lang,
    };
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  refreshInitContent() {
    if (this.editorRef) {
      this.editorRef.setContent(this.initContent);
    }
  }

  hasContent() {
    const charCount = this.editorRef !== null ? this.editorRef.plugins.wordcount.body.getCharacterCount() : 0;
    const noSpaceCharCount = this.editorRef !== null ? this.editorRef.plugins.wordcount.body.getCharacterCountWithoutSpaces() : 0;
    const hasContent = noSpaceCharCount > 0 || (this.content.trim().includes('img'));
    if (hasContent) {
      if (charCount <= this.maxLength) {
        this.valid = true;
        this.error = '';
      } else {
        this.valid = false;
        this.error = 'richTextMaxLength';
      }
    } else {
      this.valid = false;
      this.error = 'requiredField';
    }
    this.$emit('validContent', {
      content: this.content,
      valid: this.valid,
      richTextIndex: this.richTextIndex,
      error: this.error,
    });
    return hasContent;
  }

  @Watch('$store.state.lang.lang', { deep: true })
  onLangChanged(lang) {
    localStorage.setItem('latex-editor-lang', lang);
  }

  onInit(event, editor) {
    // FIXME this is very inefficient
    // it is better to "submit" editor content rather than emitting it on every change
    this.editorRef = editor;
    if (this.validateOnInit) {
      this.hasContent();
    }
    editor.on('GetContent', (e) => {
      if (!e.selection) {
        const charCount = editor.plugins.wordcount.body.getCharacterCount();
        const noSpaceCharCount = editor.plugins.wordcount.body.getCharacterCountWithoutSpaces();
        let sentContent = e.content;
        // If it has text or has an image.
        if (noSpaceCharCount > 0 || e.content.trim().includes('img')) {
          if (charCount <= this.maxLength) {
            this.valid = true;
            this.error = '';
          } else {
            this.valid = false;
            this.error = 'richTextMaxLength';
          }
        } else if (this.required) {
          this.valid = false;
          this.error = 'requiredField';
        } else {
          sentContent = '';
        }
        this.$emit('validContent', {
          content: sentContent,
          valid: this.valid,
          richTextIndex: this.richTextIndex,
          error: this.error,
        });
      }
    });
    // Work around to fix select all delete issue.
    editor.on('setContent', (e) => {
      if (e.content === '') {
        this.valid = false;
        this.error = 'requiredField';
        this.$emit('validContent', {
          content: e.content,
          valid: this.valid,
          richTextIndex: this.richTextIndex,
          error: this.error,
        });
      }
    });
    // Work around to limit uploaded file size.
    editor.on('BeforeSetContent', (e) => {
      if (e.content && e.content.includes('blob:')) {
        const blobURI = e.content
          .substr(e.content.indexOf('blob'), e.content.length)
          .replace('/>', '')
          .replace('>', '')
          .replace('"', '')
          .trim();
        if (e.target.editorUpload.blobCache.getByUri(blobURI)) {
          const allowedSize = 3 * 1048576; // 3MB
          const contentSize = this.getObjectSize(this.content);
          const fileSize = e.target.editorUpload.blobCache.getByUri(blobURI).blob().size;
          const convertedFileSize = Math.round(4 * (fileSize / 3));
          if (contentSize + convertedFileSize > allowedSize) {
            this.errorToaster({
              ar: 'تم إلغاء تحميل الملف لأنه سيتجاوز الحد الأقصى للسعة وهو 3 ميجابايت',
              en: 'File upload cancelled because it will exceed the max capacity limit of 3 MB',
            });
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }
    });
  }
}

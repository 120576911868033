import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ObjectSize extends Vue {
  // Mixin for getting object size in bytes.
  getObjectSize(obj) {
    let bytes = 0;
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number': {
          bytes += 8;
          break;
        }
        case 'string': {
          let charsNo = obj.length;
          const arabicChar = obj.match(/[\u0600-\u06FF]/g);
          if (arabicChar) {
            bytes += arabicChar.length * 2;
            charsNo -= arabicChar.length;
          }
          bytes += charsNo;
          break;
        }
        case 'boolean': {
          bytes += 4;
          break;
        }
        case 'object': {
          const objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === 'Object' || objClass === 'Array') {
            Object.keys(obj).forEach((key) => {
              bytes += this.getObjectSize(obj[key]);
            });
          } else bytes += obj.toString().length * 2;
          break;
        }
        default: {
          break;
        }
      }
    }
    return bytes;
  }
}









































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Utils from '@/shared/utils';
import arTranslate from '@/i18n/ar';
import enTranslate from '@/i18n/en';
import { QUESION_TYPES, SUPPORTED_LANGS } from '../../enums';
import { Answer } from '../../types/questions.type';
import RichTextComponent from '../../shared/richText.component.vue';

@Component({
  components: { RichTextComponent },
})
export default class QuestionAnswersForm extends Vue {
  @Prop(String) readonly actionType!: string;

  @Prop(String) readonly questionType!: string;

  @Prop(Array) readonly questionAnswers!: Answer[];

  @Prop(String) readonly questionLang!: string | null;

  @Prop(Boolean) readonly showExportForBSheet!: boolean;

  answersErrors: string[] = [];

  correctAnswerIndex = 0;

  questionTypes = QUESION_TYPES;

  refreshEditor = false;

  canExportForBSheet = false;

  $refs!: {
    tfForm: HTMLFormElement;
    questionAnswerEditors: RichTextComponent[];
  };

  rules = {
    TFAnswer: [
      (val: string) => !this.TFAnswerMissed || !!(val && val.trim().length) || this.$t('validations.required'),
      (val: string) => val.trim().length <= 500
        || this.$t('validations.maxLength', { max: 500 }),
    ],
  };

  get TFAnswerMissed() {
    return this.questionAnswers.some((ans) => (ans.text && ans.text.trim()))
      && !this.questionAnswers.every((ans) => (ans.text && ans.text.trim()));
  }

  get canAddAnswer() {
    return this.questionAnswers.length < 5;
  }

  get canRemoveAnswer() {
    return this.questionAnswers.length > 3;
  }

  @Watch('questionAnswers', { deep: true })
  onValidationChange(newVal) {
    if ((this.questionType === this.questionTypes.T_F.id) && this.$refs.tfForm) {
      this.$refs.tfForm.validate();
    }
    const isValid = !newVal.some((ans) => !ans.valid);
    this.$emit('validationChange', isValid);
  }

  @Watch('questionLang')
  onQuestionLangChange(value) {
    if (!value) {
      this.canExportForBSheet = false;
    }
  }

  mounted() {
    if (!this.questionAnswers.length) {
      this.correctAnswerIndex = -1;
      this.initAnswersData();
    } else {
      this.initAnswersValidations();
      if (this.questionType !== this.questionTypes.MMCQ.id) {
        this.initCorrectAnswer();
      }
    }
    this.checkExportForBSheet();
  }

  updated() {
    if (this.refreshEditor) {
      this.$refs.questionAnswerEditors.forEach((editor) => {
        editor.refreshInitContent();
      });
      this.refreshEditor = false;
    }
  }

  initAnswersData() {
    this.questionAnswers.push({
      text: '',
      correct: 0,
      sort: 0,
      valid: true,
      key: 0,
    });
    if (this.questionType === this.questionTypes.T_F.id) {
      this.questionAnswers.push({
        text: '',
        correct: 0,
        sort: 1,
        valid: true,
        key: 1,
      });
    } else if (this.questionType !== this.questionTypes.ESSAY.id) {
      this.questionAnswers.push(
        {
          text: '',
          correct: 0,
          sort: 1,
          valid: true,
          key: 1,
        },
        {
          text: '',
          correct: 0,
          sort: 2,
          valid: true,
          key: 2,
        },
        {
          text: '',
          correct: 0,
          sort: 3,
          valid: true,
          key: 3,
        },
      );
    }
  }

  initAnswersValidations() {
    for (let index = 0; index < this.questionAnswers.length; index += 1) {
      this.questionAnswers[index].valid = true;
      this.questionAnswers[index].key = index;
    }
  }

  validateAndSetAnswersText({ content, valid, error }, ansIndex) {
    const answerData = this.questionAnswers[ansIndex];
    if (answerData && answerData !== undefined) {
      answerData.valid = valid;
      if (answerData.valid) {
        answerData.text = content;
      } else {
        this.answersErrors[ansIndex] = error;
      }
      this.questionAnswers.splice(ansIndex, 1, answerData);
    }
  }

  addAnswer() {
    const lastKeyIndex = this.questionAnswers[this.questionAnswers.length - 1]
      ?.key;
    this.questionAnswers.push({
      text: '',
      correct: 0,
      sort: this.questionAnswers.length,
      valid: true,
      key: lastKeyIndex ? lastKeyIndex + 1 : this.questionAnswers.length,
    });
  }

  removeAnswer(ansIndex) {
    for (let index = 0; index < this.questionAnswers.length; index += 1) {
      if (index > ansIndex) {
        this.questionAnswers[index].sort = (this.questionAnswers[index]?.sort || 1) - 1;
      }
    }
    this.questionAnswers.splice(ansIndex, 1);
    this.refreshEditor = true;
  }

  initCorrectAnswer() {
    const correctAns = this.questionAnswers.findIndex(
      (ans) => ans.correct === 1,
    );

    this.correctAnswerIndex = correctAns;
    if (this.questionAnswers[this.correctAnswerIndex]) this.questionAnswers[this.correctAnswerIndex].correct = 1;
  }

  updateCorrectAnswer() {
    for (let index = 0; index < this.questionAnswers.length; index += 1) {
      const isCorrect = this.questionAnswers[index].key === this.correctAnswerIndex;
      this.questionAnswers[index].correct = isCorrect ? 1 : 0;
    }
  }

  validateAnswersFrom() {
    if ((this.questionType === this.questionTypes.T_F.id) && this.$refs.tfForm) {
      this.$refs.tfForm.validate();
    }
    const isValid = !this.questionAnswers.some((ans) => !ans.valid);
    let totalFormsValidation = true;
    for (let index = 0; index < this.questionAnswers.length; index += 1) {
      totalFormsValidation = totalFormsValidation
        && Boolean(this.questionAnswers[index].valid);
    }
    return isValid && totalFormsValidation;
  }

  handleExportForBSheetChange(): void {
    if (this.canExportForBSheet) {
      this.questionAnswers.forEach((answer: Answer) => {
        /* eslint-disable no-param-reassign */
        answer.correct = 0;
      });
      this.correctAnswerIndex = -1;
      if (this.questionLang === SUPPORTED_LANGS.EN) {
        this.questionAnswers[0].text = enTranslate.TRUE;
        this.questionAnswers[1].text = enTranslate.FALSE;
      } else if (this.questionLang === SUPPORTED_LANGS.AR) {
        this.questionAnswers[0].text = arTranslate.TRUE;
        this.questionAnswers[1].text = arTranslate.FALSE;
      }
    }
  }

  checkExportForBSheet(): void {
    if (
      (
        this.questionLang === SUPPORTED_LANGS.EN
        && this.formalizeText(this.questionAnswers[0].text) === this.formalizeText(enTranslate.TRUE)
        && this.formalizeText(this.questionAnswers[1].text) === this.formalizeText(enTranslate.FALSE)
      )
      || (
        this.questionLang === SUPPORTED_LANGS.AR
        && this.formalizeText(this.questionAnswers[0].text) === this.formalizeText(arTranslate.TRUE)
        && this.formalizeText(this.questionAnswers[1].text) === this.formalizeText(arTranslate.FALSE)
      )
    ) {
      this.canExportForBSheet = true;
    }
  }

  formalizeText(text = ''): string {
    return Utils.normalizeString(text).trim();
  }
}
